import { jwtDecode } from "jwt-decode";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";

import { API } from "@/auth/config";
import { useToken } from "@/lib/auth";
import type { AzureToken } from "@/types/azure";
import { CustomerApiRole } from "@/types/intility-sim";

interface AuthorizationContextProps {
	isCustomerAdmin: boolean;
	isFinancialAdmin: boolean;
}

const AuthorizationContext = createContext<AuthorizationContextProps>(
	{} as AuthorizationContextProps,
);

interface AuthorizationProviderProps {
	children: ReactNode;
}

const AuthorizationProvider = ({ children }: AuthorizationProviderProps) => {
	const token = useToken([API.Sim.scope]);
	const [isCustomerAdmin, setIsCustomerAdmin] = useState(false);
	const [isFinancialAdmin, setIsFinancialAdmin] = useState(false);

	useEffect(() => {
		const handleSetCustomerAdmin = (isAdmin: boolean) => {
			setIsCustomerAdmin(isAdmin);
		};

		const handleSetFinancialAdmin = (isFinancialPersonnel: boolean) => {
			setIsFinancialAdmin(isFinancialPersonnel);
		};

		if (token) {
			const decodedToken = jwtDecode<AzureToken>(token.accessToken);

			if (decodedToken.roles) {
				handleSetCustomerAdmin(
					decodedToken.roles.includes(CustomerApiRole.Admin),
				);
				handleSetFinancialAdmin(
					decodedToken.roles.includes(CustomerApiRole.Financial),
				);
			}
		}
	}, [token]);

	return (
		<AuthorizationContext.Provider
			value={{ isCustomerAdmin, isFinancialAdmin }}
		>
			{children}
		</AuthorizationContext.Provider>
	);
};

const useIsAdmin = () => {
	const { isCustomerAdmin } = useContext(AuthorizationContext);

	return isCustomerAdmin;
};

const useIsFinancialAdmin = () => {
	const { isFinancialAdmin } = useContext(AuthorizationContext);

	return isFinancialAdmin;
};

export { AuthorizationProvider, useIsAdmin, useIsFinancialAdmin };
