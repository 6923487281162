export enum QueryKey {
  HistoricalCompanyConsumptionTelia = 'HistoricalCompanyConsumptionTelia',
  HistoricalCompanyConsumptionTelenor = 'HistoricalCompanyConsumptionTelenor',
  Recommendations = 'Recommendations',
  Users = 'Users',
  InvoicePolicyUsers = 'InvoicePolicyUsers',
  TermsAgreementStatus = 'TermsAgreementStatus',
  ApnNetworkConfigs = 'ApnNetworkConfigs',
  ProfilePicture = 'ProfilePicture',
  Articles = 'Articles',
  MyUser = 'MyUser',
  MSGraph = 'MSGraph',
  HistoricalConsumptionTelia = 'HistoricalConsumptionTelia',
  HistoricalConsumptionTelenor = 'HistoricalConsumptionTelenor',
  SIM = 'SIM',
  DatalimitNotificationSetting = 'DatalimitNotificationSetting',
  SubscriptionsDistribution = 'SubscriptionsDistribution',
  BusinessSubscriptions = 'BusinessSubscriptions',
  InfiniteBusinessSubscriptions = 'InfiniteBusinessSubscriptions',
  InvoiceControlPolicies = 'InvoiceControlPolicies',
  InvoiceControlReport = 'InvoiceControlReport',
  InvoiceControlReportAvailableMonths = 'InvoiceControlReportAvailableMonths',
  InvoiceControlReportRecipients = 'InvoiceControlReportRecipients',
  RotUsers = 'RotUsers',
  CurrentMonthDataUsage = 'CurrentMonthDataUsage',
  SubscriptionOrders = 'SubscriptionOrders',
  DataPlans = 'DataPlans',
  Locations = 'Locations',
  SimAvailability = 'SimAvailability',
  BillingCompanies = 'BillingCompanies',
  EsimStatus = 'EsimStatus',
  EsimActivationProfile = 'EsimActivationProfile',
  SubscriptionDataPlanHistory = 'SubscriptionDataPlanHistory',
  RoamingPrices = 'RoamingPrices',
  OrderEsim = 'OrderEsim',
  OrderEsimStatus = 'OrderEsimStatus',
  SubscriptionServices = 'SubscriptionServices',
  SimOrders = 'SimOrders',
  InvoiceHistory = 'InvoiceHistory',
  ShowInterest = 'ShowInterest',
  UsersWithSubscription = 'UsersWithActiveSubscription',
  DetailedInvoice = 'DetailedInvoice',
  InvoicePolicy = 'InvoicePolicy',
  BilledBillingCompanies = 'BilledBillingCompanies',
}
