import { faBuilding, faCodeBranch, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Icon, Nav, Spinner, Switch, Tooltip } from '@intility/bifrost-react';
import { useFlag, useUnleashClient } from '@unleash/proxy-client-react';
import { useContext } from 'react';

import { DemoContext } from '@/context/DemoContext';
import { useGraph } from '@/lib/graph';
import { cn } from '@/utils/clsx';
import { env } from '@/utils/env';

export const TopNav = () => {
  const appVersion = env.version;
  const isStaging = window.location.hostname.includes('sim-staging');
  const versionText = !isStaging ? `v${appVersion}` : `v${appVersion} - Staging`;
  const { isDemoMode, toggleDemoMode } = useContext(DemoContext);

  const { data } = useGraph();

  const userInfo = data?.userInfo;

  const client = useUnleashClient();
  const showFeatureFlagList = useFlag('show-feature-flag-list');

  const activeToggles = client.getAllToggles();

  return (
    <>
      {/* <div className={'block md:hidden'}>
        <Nav.Item
          onClick={() =>
            handleModal(<UserFeedback isModal={true} />, 'Hvordan liker du Intility SIM?')
          }
          className={'cursor-pointer hover:[&>svg]:text-bfc-theme'}
          icon={faCommentSmile}
        />
      </div> */}
      <Nav.Group name={<ProfilePicture small />}>
        <Grid className='p-bfs-12'>
          <Grid>
            <div>
              <Icon icon={faUser} marginRight />
              {userInfo?.givenName} {userInfo?.surname}
            </div>
            <div>
              <Icon icon={faBuilding} marginRight />
              {userInfo?.companyName}
            </div>
            <div>
              <Icon icon={faCodeBranch} marginRight />
              {appVersion ? versionText : 'Localhost'}
            </div>
          </Grid>
        </Grid>
        <hr />
        <Grid className='p-bfs-12'>
          <Switch
            className='w-fill !mx-0 !mb-0'
            label='Demo modus'
            checked={isDemoMode}
            onChange={() => toggleDemoMode()}
          />
          {showFeatureFlagList && (
            <Tooltip
              placement='bottom'
              state='neutral'
              content={
                <Grid cols={1}>
                  {activeToggles
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(toggle => (
                      <div key={toggle.name}>{toggle.name}</div>
                    ))}
                </Grid>
              }
            >
              <p>Aktive feature flags</p>
            </Tooltip>
          )}
        </Grid>
      </Nav.Group>
    </>
  );
};

interface ProfilePictureProps {
  small?: boolean;
}

const ProfilePicture = ({ small }: ProfilePictureProps) => {
  const { data, isLoading, isError } = useGraph();
  const { isDemoMode } = useContext(DemoContext);
  const userImgUrl = data?.userImgUrl;
  const userInfo = data?.userInfo;

  return (
    <div className={cn(`cursor-pointer align-baseline`, isDemoMode && 'blur-sm')}>
      {isLoading ? (
        <Spinner
          data-testid={'loading-spinner'}
          className={`${small ? '' : 'p-bfs-24'}`}
          size={small ? 14 : 70}
        />
      ) : isError || !userImgUrl ? (
        <Icon data-testid={'user-icon'} icon={'user'} />
      ) : userImgUrl ? (
        <img
          className='h-[28px] w-[28px] rounded-[50%]'
          src={userImgUrl}
          alt={userInfo?.givenName ?? 'user image'}
        />
      ) : null}
    </div>
  );
};
