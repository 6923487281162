import {
  faChartMixedUpCircleDollar,
  faDownload,
  faMoneyBill,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowLeft,
  faCartPlus,
  faChartNetwork,
  faFileInvoice,
  faFileInvoiceDollar,
  faListCheck,
  faSignal,
  faUser,
  faUserGroup,
  faUserLock,
} from '@fortawesome/pro-solid-svg-icons';
import { Button, Nav } from '@intility/bifrost-react';
import { useFlag } from '@unleash/proxy-client-react';
import { useContext } from 'react';

import { A2HSContext } from '@/context/A2HSContext';
import { useIsAdmin, useIsFinancialAdmin } from '@/context/AuthorizationContext';
import { routes } from '@/routes/config';
import { FeatureFlags } from '@/types/feature-flags';
import { env } from '@/utils/env';
import { NavLink } from './NavLink';

const { environment } = env;

export const SideNav = () => {
  const isCustomerAdmin = useIsAdmin();
  const isFinancialAdmin = useIsFinancialAdmin();
  const showOrderFlowV2Flag = useFlag(FeatureFlags.USE_ORDER_FLOW_V2);
  const showInvoiceControlFlag = useFlag(FeatureFlags.USE_INVOICE_CONTROL);

  const { pwaInstallPrompt, showPwaInstall } = useContext(A2HSContext);
  return (
    <div className={'flex h-full flex-col justify-between'}>
      <div>
        {environment === 'development' && (
          <NavLink to={routes.landing.base.path}>
            <Nav.Item icon={faArrowLeft}>Tilbake til landingsside</Nav.Item>
          </NavLink>
        )}
        <NavLink to={routes.subscriptions.user.path}>
          <Nav.Item icon={faUser}>Mine abonnementer</Nav.Item>
        </NavLink>
        {isCustomerAdmin && (
          <>
            <NavLink
              to={
                showOrderFlowV2Flag
                  ? routes.admin.companySubscriptions.path
                  : routes.subscriptions.company.path
              }
            >
              <Nav.Item icon={faUserGroup}>Alle abonnementer</Nav.Item>
            </NavLink>
            <NavLink to={showOrderFlowV2Flag ? routes.admin.products.path : routes.products.path}>
              <Nav.Item icon={faCartPlus}>Bestill abonnement</Nav.Item>
            </NavLink>
          </>
        )}

        <NavLink to={routes.orders.path}>
          <Nav.Item icon={faListCheck}>
            {isCustomerAdmin ? 'Bestillinger' : 'Mine bestillinger'}
          </Nav.Item>
        </NavLink>
        {/* <NavLink to={routes.recommendations.path}>
          <Nav.Item icon={faClipboardCheck}>Anbefalinger</Nav.Item>
        </NavLink> */}
        {(isCustomerAdmin || isFinancialAdmin) && (
          <Nav.Group icon={faFileInvoice} name='Økonomi'>
            {showInvoiceControlFlag && (
              <NavLink to={routes.financial.invoiceControl.path}>
                <Nav.Item icon={faFileInvoiceDollar}>Fakturakontroll</Nav.Item>
              </NavLink>
            )}

            <NavLink to={routes.financial.detailedInvoice.path}>
              <Nav.Item icon={faChartMixedUpCircleDollar}>Fakturagrunnlag</Nav.Item>
            </NavLink>
          </Nav.Group>
        )}
        {isCustomerAdmin && (
          <NavLink to={routes.iot.path}>
            <Nav.Item icon={faChartNetwork}>Mobilnett</Nav.Item>
          </NavLink>
        )}
        {isCustomerAdmin && (
          <NavLink to={routes.admin.userManagement.path}>
            <Nav.Item icon={faUserLock}>Tilgangsstyring</Nav.Item>
          </NavLink>
        )}
        <Nav.Group icon={faMoneyBill} name='Priser og vilkår'>
          <NavLink to={routes.terms.path}>
            <Nav.Item>Vilkår for bruk</Nav.Item>
          </NavLink>
          <NavLink to={routes.callRates.domesticNumberPlans.path}>
            <Nav.Item>Priser i Norge </Nav.Item>
          </NavLink>
          <NavLink to={routes.callRates.roam.path}>
            <Nav.Item>Utlandspriser</Nav.Item>
          </NavLink>
        </Nav.Group>
        <NavLink to={routes.coverage.path}>
          <Nav.Item icon={faSignal}>Dekningskart</Nav.Item>
        </NavLink>
      </div>

      {showPwaInstall && (
        <div
          className={
            'pwa-install-prompt flex flex-col items-center gap-bfs-16 border-t border-bfc-base-dimmed'
          }
        >
          <img
            className={'pwa-icon m-auto w-[90px] border border-bfc-base-dimmed'}
            src={new URL('/favicon-512.png', import.meta.url).href}
            alt={'Intility SIM logo'}
          />
          <Button small pill onClick={() => pwaInstallPrompt()} className={'a2hs-btn-full w-full'}>
            Installer app
          </Button>
          <Button
            icon={faDownload}
            noPadding
            onClick={() => pwaInstallPrompt()}
            className={'a2hs-btn-collapsed'}
          />
        </div>
      )}
    </div>
  );
};
